import React from "react";
import { Container } from "react-bootstrap";


function FairView() {

  return (<Container fluid>
    We strive to respect the fair principles, stay tuned for details
  </Container>);

}

export default FairView;
